import React, { useEffect } from 'react';
import { Col, Container, Row, Accordion } from 'react-bootstrap';
import { Header, Footer, SlickSlider, GotoTop } from '../../component';
import {
    home_banner,
    eclipse1
} from '../../assets';


const testimonials = [
    {
        img: eclipse1,
        hd: 'Kenned Dsouza',
        txt: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor perspiciatis nam tempore nostrum labore aspernatur incidunt repellendus quae, nemo voluptates ipsa magnam hic quis consectetur cum harum ad neque nesciunt.',
    },
    {
        img: eclipse1,
        hd: 'Kenned Jones',
        txt: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor perspiciatis nam tempore nostrum labore aspernatur incidunt repellendus quae, nemo voluptates ipsa magnam hic quis consectetur cum harum ad neque nesciunt.',
    },
    {
        img: eclipse1,
        hd: 'Kenned Armstrong',
        txt: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor perspiciatis nam tempore nostrum labore aspernatur incidunt repellendus quae, nemo voluptates ipsa magnam hic quis consectetur cum harum ad neque nesciunt.',
    },
    {
        img: eclipse1,
        hd: 'Kenned Hunt',
        txt: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor perspiciatis nam tempore nostrum labore aspernatur incidunt repellendus quae, nemo voluptates ipsa magnam hic quis consectetur cum harum ad neque nesciunt.',
    },
]

const tableData = [
    [
        'PHP Basic',
        '95',
        '4.5',
        '427.5',
        '30,000',
        '70',
        '7,500'
    ],
    [
        'Java Basic',
        '95',
        '4.5',
        '427.5',
        '30,000',
        '70',
        '7,500'
    ],
    [
        'System Basic',
        '95',
        '4.5',
        '427.5',
        '30,000',
        '70',
        '7,500'
    ],
    [
        'Networking Basic',
        '95',
        '4.5',
        '427.5',
        '30,000',
        '70',
        '7,500'
    ],
    [
        'PHP Advance',
        '95',
        '4.5',
        '427.5',
        '60,000',
        '140',
        '15,000'
    ],
    [
        'Java Advance',
        '95',
        '4.5',
        '427.5',
        '60,000',
        '140',
        '15,000'
    ],
    [
        'System Advance',
        '95',
        '4.5',
        '427.5',
        '60,000',
        '140',
        '15,000'
    ],
    [
        'Networking Advance',
        '95',
        '4.5',
        '427.5',
        '60,000',
        '140',
        '15,000'
    ],
    [
        'iPhone App Dev',
        '95',
        '4.5',
        '427.5',
        '100,000',
        '234',
        '25,000'
    ],
]

const courseData = [
    {
        id: 0,
        title: 'English Language Training',
        hd1: 'English Language Training',
        disc: 'Meet the growing demand for communicating in English by sharpening the English language skills. 1st to 4th semester students from all universities are encouraged to register. Various sessions throughout the day will be offered for the classes starting 8:00 AM until 8:00 PM.',
        hd2: 'Course Outline',
        out: ['Reading', 'Writing', 'Listening', 'Speaking']
    },
    {
        id: 1,
        title: 'Currently Registration is Closed',
        hd1: 'Network Administration Training (Basic Level)',
        disc: 'Meet the growing demand for communicating in English by sharpening the English language skills. 1st to 4th semester students from all universities are encouraged to register. Various sessions throughout the day will be offered for the classes starting 8:00 AM until 8:00 PM.',
        hd2: 'Course Outline',
        out: ['']
    },
    {
        id: 2,
        title: 'Network Administration Training',
        hd1: 'Network Administration Training (Advance Level)',
        disc: 'Meet the growing demand for communicating in English by sharpening the English language skills. 1st to 4th semester students from all universities are encouraged to register. Various sessions throughout the day will be offered for the classes starting 8:00 AM until 8:00 PM.',
        hd2: 'Course Outline',
        out: ['']
    },
    {
        id: 3,
        title: 'Software Development Training',
        hd1: 'Web Development Training Using PHP With MySQL (Advance Level)',
        disc: 'Meet the growing demand for communicating in English by sharpening the English language skills. 1st to 4th semester students from all universities are encouraged to register. Various sessions throughout the day will be offered for the classes starting 8:00 AM until 8:00 PM.',
        hd2: 'Course Outline',
        out: ['']
    },
    {
        id: 4,
        title: 'Software Development Training',
        hd1: 'ASP.NET 4.0 Using C# Training (Basic Level)',
        disc: 'Meet the growing demand for communicating in English by sharpening the English language skills. 1st to 4th semester students from all universities are encouraged to register. Various sessions throughout the day will be offered for the classes starting 8:00 AM until 8:00 PM.',
        hd2: 'Course Outline',
        out: ['']
    },
    {
        id: 5,
        title: 'Software Development Training',
        hd1: 'ASP.NET 4.0 Using C# Training (Advance Level)',
        disc: 'Meet the growing demand for communicating in English by sharpening the English language skills. 1st to 4th semester students from all universities are encouraged to register. Various sessions throughout the day will be offered for the classes starting 8:00 AM until 8:00 PM.',
        hd2: 'Course Outline',
        out: ['']
    },
    {
        id: 6,
        title: 'Software Development Training',
        hd1: 'Java Essential Training (Basic Level)',
        disc: 'Meet the growing demand for communicating in English by sharpening the English language skills. 1st to 4th semester students from all universities are encouraged to register. Various sessions throughout the day will be offered for the classes starting 8:00 AM until 8:00 PM.',
        hd2: 'Course Outline',
        out: ['']
    },
    {
        id: 7,
        title: 'Software Development Training',
        hd1: 'Java Essential Training (Basic Level)',
        disc: 'Meet the growing demand for communicating in English by sharpening the English language skills. 1st to 4th semester students from all universities are encouraged to register. Various sessions throughout the day will be offered for the classes starting 8:00 AM until 8:00 PM.',
        hd2: 'Course Outline',
        out: ['']
    },
    {
        id: 8,
        title: 'Software Development Training',
        hd1: 'Java Essential Training (Basic Level)',
        disc: 'Meet the growing demand for communicating in English by sharpening the English language skills. 1st to 4th semester students from all universities are encouraged to register. Various sessions throughout the day will be offered for the classes starting 8:00 AM until 8:00 PM.',
        hd2: 'Course Outline',
        out: ['']
    },
    {
        id: 9,
        title: 'Software Development Training',
        hd1: 'Java Essential Training (Basic Level)',
        disc: 'Meet the growing demand for communicating in English by sharpening the English language skills. 1st to 4th semester students from all universities are encouraged to register. Various sessions throughout the day will be offered for the classes starting 8:00 AM until 8:00 PM.',
        hd2: 'Course Outline',
        out: ['']
    },
]

const TrainingProgram: React.FunctionComponent = () => {
    useEffect(() => {
        document.title = 'Home | INV';
    });
    return (
        <>
            <Header otClass='transparent' />
            <section className="banner home_banner">
                <Container >
                    <Row className='align-items-center'>
                        <Col lg={7} md={7} sm={12}>
                            <div className="caption">
                                <h2 className='txt_white mb-5' style={{ maxWidth: '100%' }}>TRAINING PROGRAMS</h2>
                                <p className='txt_white' style={{ maxWidth: '500px' }}>
                                    Candidates can apply in only one training program for the batch 2K22 Feb-May
                                    <br />
                                    <br />
                                    Tuition: Hidaya charges tuition for various technologies as shown in the table. Interns who qualify for receiving Zakat due to financial weak background are required to fill a form. During interview interns who are requesting for free tuition are required to submit their parents taxes for last year, electric bill, gas bill, etc. to prove economically weak background.
                                    <br />
                                    <br />
                                    Stipend: Hidaya offers stipend to students who qualify for receiving Zakat due to financial weak background. Such interns are required to fill a form as well. During interview interns who are requesting for stipend are required to submit their parents taxes for the last year, velectric bill, gas bill, etc. to prove economically weak background.
                                </p>
                            </div>
                        </Col>
                        <Col lg={5} md={5} sm={12}>
                            <div className="img_wrapper">
                                <img src={home_banner} alt="" className="img_fluid" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <main className='home_page' style={{background: '#F3F6FB'}}>
                <section className=''>
                    <Container>
                        <Row>
                            <Col>
                                <div className="table-responsive">
                                    <table className="table box_shad">
                                        <thead className="box_shad">
                                            <tr>
                                                <th className='text-center txt_white'>Technologies</th>
                                                <th className='text-center txt_white'>Duration in Days</th>
                                                <th className='text-center txt_white'>Daily Hours</th>
                                                <th className='text-center txt_white'>Total Training Hours</th>
                                                <th className='text-center txt_white'>Total Fees in Rupees</th>
                                                <th className='text-center txt_white'>Per Hour Fees in Rupees</th>
                                                <th className='text-center txt_white'>Per Month Fees in Rupees</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.map((item: Array<string>) => {
                                                return (
                                                    <tr key={Math.random()}>
                                                        {item.map((value: String) => {
                                                            return (
                                                                <td align='center' key={Math.random()}>
                                                                    <span>{value}</span>
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Accordion defaultActiveKey="0">
                                    {courseData.map((item) => {
                                        return (
                                            <Accordion.Item className="mb-4 box_shad" eventKey={item.id.toString()} key={item.id}>
                                                <Accordion.Header>{item.title}</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="acc_body_content">
                                                        <h4 className="txt_dark">{item.hd1}</h4>
                                                        <p className="txt_dark">{item.disc}</p>
                                                        <h4 className="acc_hd">{item.hd1}</h4>
                                                        <div className="body_foo">
                                                            <ol>
                                                                {item.out.map((value) => {
                                                                    return (
                                                                        <li className='mg_0' key={Math.random()}>{value}</li>
                                                                    )
                                                                })}
                                                            </ol>
                                                            <div className="text-center">
                                                                <button className='btn common_btn hover txt_white reg_btn'>
                                                                    Currently Registration is Closed
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        );
                                    })}
                                </Accordion>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className='testi_sec'>
                    <Container className='fluid_sm' >
                        <Row>
                            <Col>
                                <h2 className='sec_hd txt_dark_blue text-center w_sm'>
                                Here's what pet owners have to say about our services
                                </h2>
                                <SlickSlider
                                    items={testimonials}
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <Footer />
            <GotoTop />
        </>
    );
}

export default TrainingProgram;

import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Accordion, Form, InputGroup, Button } from 'react-bootstrap';
import { Header, Footer, SlickSlider } from '../../component';
import { logo, login_poster } from '../../assets'
import { Link } from 'react-router-dom';

const Registration: React.FunctionComponent = () => {
    useEffect(() => {
        document.title = 'Registration | INV';
    });
    const [validated, setValidated] = useState(false);

    const handleSubmit = ($event: any) => {
        const form = $event.currentTarget;
        if (form.checkValidity() === false) {
            $event.preventDefault();
            $event.stopPropagation();
        }

        setValidated(true);
    };
    return (
        <div className="main_wrapper">
            <Header otClass="transparent" />
            <main>
                <section className="login_sec">
                    <Container fluid>
                        <div className="d-flex md_block">
                            <div className="form_wrapper reg_wrapper text-center mb-3 px-5">
                                <div className="d-flex">
                                    <div className="form_logo">
                                        <img src={logo} className="img-fluid" />
                                    </div>
                                    <div className="text-start mx-4">
                                        <h4 className="sec_hd txt_dark_blue mt-3 mb-2">
                                            REGISTRATION FORM
                                        </h4>
                                        <p className="text-dark fw-bold">Please fill in the form below.</p>
                                        <p className="text-dark fw-bold">Fields marked with * are required.</p>
                                    </div>
                                </div>
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                        <Col lg={3}>
                                            <Form.Group as={Col} md="6" controlId="validationCustom03">
                                                <Form.Label>City</Form.Label>
                                                <Form.Control type="text" placeholder="" required />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid city.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="3" controlId="validationCustom04">
                                                <Form.Label>State</Form.Label>
                                                <Form.Control type="text" placeholder="State" required />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid state.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="3" controlId="validationCustom05">
                                                <Form.Label>Zip</Form.Label>
                                                <Form.Control type="text" placeholder="Zip" required />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid zip.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3}>
                                            <Form.Group as={Col} md="6" controlId="validationCustom03">
                                                <Form.Label>City</Form.Label>
                                                <Form.Control type="text" placeholder="" required />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid city.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                        </Col>
                                        <Col lg={3}>
                                            <Form.Group as={Col} md="6" controlId="validationCustom03">
                                                <Form.Label>City</Form.Label>
                                                <Form.Control type="text" placeholder="" required />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid city.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="3" controlId="validationCustom04">
                                                <Form.Label>State</Form.Label>
                                                <Form.Control type="text" placeholder="State" required />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid state.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} md="3" controlId="validationCustom05">
                                                <Form.Label>Zip</Form.Label>
                                                <Form.Control type="text" placeholder="Zip" required />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid zip.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Button type="submit">Submit form</Button>
                                </Form>
                            </div>
                        </div>
                    </Container>
                </section>
            </main>
            <Footer />
        </div>
    )
}

export default Registration;

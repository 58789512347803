import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
    Home,
    ComingSoon,
    Portfolio,
    ContactUs,
    PageNotFound,
    TrainingProgram,
    Login,
    Registration,
} from '../pages';
import DHome from '../pages/dashboard/d-home';

const MyRoutes: React.FunctionComponent = () => {
    return (
        <Routes>
            <Route path='/' element={< Home />} />
            <Route path='/coming-soon' element={< ComingSoon />} />
            <Route path='/portfolio' element={< Portfolio />} />
            <Route path='/contact-us' element={< ContactUs />} />
            <Route path='/training-program' element={< TrainingProgram />} />
            <Route path='/login' element={< Login />} />
            <Route path='/registration' element={<Registration />} />
            <Route path='/dashboard' element={< DHome />} />
            <Route path='/*' element={< PageNotFound />} />
        </Routes>
    );
}

export default MyRoutes;
import { faFacebookF, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faMapMarkerAlt, faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { yellow_circle_bg } from "../../assets";
import { Footer, Header, GotoTop } from "../../component";
const icons = [
  { icon: faFacebookF, url: "https://www.facebook.com/iinvtech" },
  { icon: faTwitter, url: "/" },
  { icon: faInstagram, url: "/" },
];
const info = [
  {
    icon: faMapMarkerAlt,
    hd: "Address",
    txt: "Ground Floor House# R/131 block 13D-2 Gulshan-e-Iqbal",
    url: null,
  },
  {
    icon: faMobileAlt,
    hd: "Call for queries",
    txt: "0342 555 7312",
    url: "/",
  },
  {
    icon: faEnvelope,
    hd: "Email Us",
    txt: "help@iinv.tech",
    url: "/",
  },
];
interface Iinfo {
  icon: any;
  url: string | null;
  txt: string;
  hd: string;
}
interface Iicons {
  icon: any;
  url: string;
}
const ContactUs: React.FunctionComponent = () => {
  useEffect(() => {
    document.title = "Contact Us | INV";
  }, []);
  return (
    <>
      <Header />
      <main className="contact_page">
        <Container>
          <Row>
            <Col>
              <h2 className="sec_hd mb-4 txt_blue">Get In Touch & Get Started Today!</h2>
              <p className="sec_para w_sm txt_blue text-center">Lets Us turn Your Ideas Into Realty.</p>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col lg={12} md={12} sm={12}>
              <div className="contact_wrapper">
                <div className="contact_info">
                  <h2 className="sec_hd mb-4 txt_white">Let Connect!</h2>
                  <ul>
                    {info.map((item: Iinfo) => {
                      return (
                        <li key={Math.random()}>
                          <div className="icon_wrapper">
                            <FontAwesomeIcon icon={item.icon} />
                          </div>
                          <div>
                            <h4 className="title">{item.hd}</h4>
                            {item.url ? <a href={item.url}>{item.txt}</a> : <p className="para" dangerouslySetInnerHTML={{ __html: item.txt }}></p>}
                          </div>
                        </li>
                      );
                    })}
                    <li>
                      <div className="socials">
                        {icons.map((item: Iicons) => {
                          return (
                            <a href={item.url} target="_blank" rel="noreferrer">
                              <FontAwesomeIcon icon={item.icon} />
                            </a>
                          );
                        })}
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="form_wrapper">
                  <ContactForm />
                </div>
              </div>
              <div className="bg_img">
                <img src={yellow_circle_bg} alt="" className="img_fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
      <GotoTop />
    </>
  );
};

export default ContactUs;

const ContactForm: React.FunctionComponent = () => {
  const handleInput = (ev: any): void => {};

  const handleSubmit = (ev: any): void => {
    ev.preventDefault();
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit} action="">
      <div className="input_wrapper">
        <label htmlFor="">Name</label>
        <input type="text" name="name" required value="" onChange={handleInput} placeholder="Full Name" />
      </div>
      <div className="input_wrapper">
        <label htmlFor="">Email</label>
        <input type="email" name="email" required value="" onChange={handleInput} placeholder="example@gmail.com" />
      </div>
      <div className="input_wrapper">
        <label htmlFor="">Phone Number</label>
        <input type="text" name="number" required value="" onChange={handleInput} placeholder="xxxx xxx xxxx" />
      </div>
      <div className="input_wrapper">
        <label htmlFor="">Message</label>
        <textarea name="message" required value="" rows={6} onChange={handleInput} placeholder="Message..." />
      </div>
      <input type="submit" className="common_btn blue_gradient txt_white hover" value="Send" />
    </form>
  );
};

import React, { useEffect } from 'react';
import {
    port1,
    port2,
    port3,
    port4,
    port5,
    port6,
    port7,
    port_banner,
} from '../../assets';
import {
    Container,
    Row,
    Col,
    Tab,
    Nav
} from 'react-bootstrap';
import { CommonBtn, Header, Footer, GotoTop } from '../../component';
import { useNavigate } from 'react-router-dom';

const Portfolio: React.FunctionComponent = () => {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Portfolio | INV';
    }, []);

    return (
        <>
            <Header />
            <section className='portfolio_banner'>
                <div className="inner_wrapper">
                    <Container>
                        <Row className='align-items-center'>
                            <Col lg={6} md={6} sm={12}>
                                <div className="caption">
                                    <h2 className='txt_white'>Let's show you something different!</h2>
                                    <p className='txt_white'>
                                        Let us put your idea in the palm of your customer's hand.
                                    </p>
                                    {/* <div className="wrapper">
                                        <CommonBtn
                                            myClass='common_btn white hover txt_blue'
                                            myEvent={() => navigate('/')}
                                        >
                                            Schedule Service
                                        </CommonBtn>
                                        <span className='txt_white'>Or Call (+92) 3334228324</span>
                                    </div> */}
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <div className="img_wrapper">
                                    <img src={port_banner} alt="" className="img_fluid" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
            <main>
                <PortfolioSec />
            </main>
            <Footer />
            <GotoTop />
        </>
    );
}

export default Portfolio;

const portTabs = [
    {
        key: 'first',
        txt: 'All',
        content: [
            { img: port1, class: 'grid_item' },
            { img: port2, class: 'grid_item' },
            { img: port3, class: 'grid_item' },
            { img: port4, class: 'grid_item' },
            { img: port5, class: 'grid_item span2_3' },
            { img: port6, class: 'grid_item span1_2' },
            { img: port7, class: 'grid_item' },
        ],
    },
    {
        key: 'second',
        txt: 'Logo',
        content: [
            { img: port2, class: 'grid_item' },
            { img: port1, class: 'grid_item' },
            { img: port3, class: 'grid_item' },
            { img: port4, class: 'grid_item' },
            { img: port5, class: 'grid_item span2_3' },
            { img: port6, class: 'grid_item span1_2' },
            { img: port7, class: 'grid_item' },
        ],
    },
    {
        key: 'third',
        txt: 'Brochures',
        content: [
            { img: port3, class: 'grid_item' },
            { img: port2, class: 'grid_item' },
            { img: port1, class: 'grid_item' },
            { img: port4, class: 'grid_item' },
            { img: port5, class: 'grid_item span2_3' },
            { img: port6, class: 'grid_item span1_2' },
            { img: port7, class: 'grid_item' },
        ],
    },
    {
        key: 'fourth',
        txt: 'Mobile Apps',
        content: [
            { img: port4, class: 'grid_item' },
            { img: port2, class: 'grid_item' },
            { img: port3, class: 'grid_item' },
            { img: port1, class: 'grid_item' },
            { img: port5, class: 'grid_item span2_3' },
            { img: port6, class: 'grid_item span1_2' },
            { img: port7, class: 'grid_item' },
        ],
    },
    {
        key: 'fifth',
        txt: 'Web Designs',
        content: [
            { img: port1, class: 'grid_item' },
            { img: port2, class: 'grid_item' },
            { img: port4, class: 'grid_item' },
            { img: port3, class: 'grid_item' },
            { img: port5, class: 'grid_item span2_3' },
            { img: port6, class: 'grid_item span1_2' },
            { img: port7, class: 'grid_item' },
        ],
    },
    {
        key: 'sixth',
        txt: 'Social Media Design',
        content: [
            { img: port1, class: 'grid_item' },
            { img: port4, class: 'grid_item' },
            { img: port3, class: 'grid_item' },
            { img: port2, class: 'grid_item' },
            { img: port5, class: 'grid_item span2_3' },
            { img: port6, class: 'grid_item span1_2' },
            { img: port7, class: 'grid_item' },
        ],
    },
    {
        key: 'seventh',
        txt: 'Ads Design',
        content: [
            { img: port4, class: 'grid_item' },
            { img: port3, class: 'grid_item' },
            { img: port2, class: 'grid_item' },
            { img: port1, class: 'grid_item' },
            { img: port5, class: 'grid_item span2_3' },
            { img: port6, class: 'grid_item span1_2' },
            { img: port7, class: 'grid_item' },
        ],
    },
]

interface IportTabs {
    key: string,
    txt: string,
    content: Icontent[]
}

interface Icontent {
    img: string,
    class: string,
}

const PortfolioSec: React.FunctionComponent = () => {
    return (
        <section className='portfolio_sec' >
            <Container fluid>
                <Row className='mb-5'>
                    <Col>
                        <Tab.Container id="portfolio-tabs" defaultActiveKey="first">
                            <Nav variant="pills">
                                {
                                    portTabs.map((item: IportTabs) => {
                                        return (
                                            <Nav.Item key={Math.random()}>
                                                <Nav.Link eventKey={item.key}>{item.txt}</Nav.Link>
                                            </Nav.Item>
                                        );
                                    })
                                }
                            </Nav>
                            <Tab.Content>
                                {
                                    portTabs.map((item: IportTabs) => {
                                        return (
                                            <Tab.Pane eventKey={item.key} key={Math.random()}>
                                                <div className="grid_container">
                                                    {item.content.map(item => {
                                                        return (
                                                            <div className={item.class} key={Math.random()}>
                                                                <div className="img_wrapper">
                                                                    <img src={item.img} alt="" className="img_fluid" />
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Tab.Pane>
                                        );
                                    })
                                }
                            </Tab.Content>
                        </Tab.Container>
                    </Col>
                    {/* <div className="bottom_btn_wrapper">
                        <CommonBtn
                            myClass='common_btn yellow_gradient radius_light hover txt_white'
                            myEvent={() => console.log("there")}
                        >
                            View More
                        </CommonBtn>
                    </div> */}
                </Row>
            </Container>
        </section >
    );
}
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Header, Footer } from '../../component';

const PageNotFound: React.FunctionComponent = () => {
    return (
        <>
            <Header />
            <main>
                <Container>
                    <Row>
                        <Col>
                            <div className='page_not_found'>
                                <h4>Page Not Found</h4>
                                <Link to='/'>Goto Home Page</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </main>
            <Footer />
        </>
    );
}

export default PageNotFound;
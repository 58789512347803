import React, { useEffect } from 'react';
import {
    logo,
    bg_circle_bottom,
    bg_circle_top,
    coming_soon,
} from '../../assets';
import { useNavigate } from 'react-router-dom';

const ComingSoon: React.FunctionComponent = () => {

    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Coming Soon | INV';
    }, []);
    return (
        <div className='coming_soon'>
            <div className="inner_wrapper">
                <div className="start_img">
                    <img src={bg_circle_bottom} alt='' className='img_fluid' />
                </div>
                <div className="content">
                    <div className="logo">
                        <button
                            onClick={() => navigate('/')}
                        >
                            <img src={logo} alt="" />
                        </button>
                    </div>
                    <div className="mid_sec">
                        <div className="main_img">
                            <img src={coming_soon} alt="" className="img_fluid" />
                        </div>
                        <h2>
                            Coming <span>Soon</span>
                        </h2>
                    </div>
                    <div className="bottom_wrapper">
                        <p>Privacy Policy</p>
                        <p>Facebook/Instagram/LinkedIn</p>
                    </div>
                </div>
                <div className="end_img">
                    <img src={bg_circle_top} alt='' className='img_fluid' />
                </div>
            </div>
        </div>
    );
}

export default ComingSoon;
import React, { useRef, useState } from "react";
import Slider from "react-slick";
import { arrow_left, arrow_right } from "../../assets";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  arrows: false,
  dots: false,
  speed: 800,
  slidesToShow: 3,
  slidesToScroll: 1,
  className: "testi_slider",
  centerMode: true,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        focusOnSelect: false,
        dots: true,
      },
    },
  ],
};

interface Itestimonials {
  img: string;
  clientName: string;
  review: string;
}

const SlickSlider: React.FunctionComponent<any> = (props) => {
  const { items } = props;
  const slider: any = useRef(null);

  return (
    <div className="slider_wrapper">
      <Slider {...settings} ref={slider}>
        {items.map((item: Itestimonials, i: number) => (
          <SliderItem item={item} key={i} />
        ))}
    
      </Slider>

      <div className="prev_btn nav_btn">
        <img src={arrow_left} alt="" onClick={() => slider.current.slickPrev()} className="img_fluid" />
      </div>
      <div className="next_btn nav_btn">
        <img src={arrow_right} alt="" onClick={() => slider.current.slickNext()} className="img_fluid" />
      </div>
    </div>
  );
};

export default SlickSlider;

const SliderItem: React.FunctionComponent<any> = ({ item }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const toggleReadMore = () => {
    setIsTruncated(!isTruncated);
  };
  const displayText = isTruncated ? limitString(item?.review, 150) : limitString(item?.review, 250);
  return (
    <div className="item" key={Math.random()}>
      <div className="inner_wrapper">
        <div className="img_wrapper">
          <img src={item.img} alt="" className="rounded-circle img_fluid" />
        </div>
        <h4>{item.clientName}</h4>
        <p className={`para  `} style={{ color: "black" }}>
          {item?.projectTitle}
        </p>
        <p className={`para ${isTruncated ? "" : "expanded"}`}>
          {displayText} &nbsp;
          {item?.review?.length > 151 && (
            <span className="read-more" onClick={toggleReadMore}>
              {isTruncated ? "read more" : "show less"}
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

function limitString(str: string | undefined, maxLength: number) {
  if (str?.length && str.length > maxLength) {
    return str.slice(0, maxLength);
  }
  return str;
}

import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Footer, Header, GotoTop } from '../../component';
import {
    home_banner,
    Questions_bro,
    yellow_circle_sm,
    service1_a,
    service1_b,
    service2_a,
    service2_b,
    service3_a,
    service3_b,
    service4_a,
    service4_b,
    service5_a,
    service5_b,
    service6_a,
    service6_b,
    yellow_circle_bg,
    globe,
    p_active,
    satisfied,
    p_complete,
    blue_flow,
} from '../../assets';
import { SlickSlider } from '../../component';
import { reviews } from './data';

const sericeCards = [
    { imga: service1_a, imgb: service1_b, txt: 'Web Development' },
    { imga: service2_a, imgb: service2_b, txt: 'App Development' },
    { imga: service3_a, imgb: service3_b, txt: 'IT Consulting' },
    { imga: service4_a, imgb: service4_b, txt: 'SEO Optimization' },
    { imga: service5_a, imgb: service5_b, txt: 'UI/UX Design' },
    { imga: service6_a, imgb: service6_b, txt: 'Logo Designing' },
]

const projectCards = [
    { img: p_complete, hd: reviews.length.toString() + "+", txt: 'Projects Complete' },
    { img: p_active, hd: '10+', txt: 'Active Projects' },
    { img: globe, hd: '2', txt: 'Country Available' },
    { img: satisfied, hd: reviews.length.toString() + "+", txt: 'Client Satisfied' },
]

interface IprojectCards {
    img: string,
    hd: string,
    txt: string,
}
interface IsericeCards {
    imga: string,
    imgb: string,
    txt: string,
}


const Home: React.FunctionComponent = () => {

    useEffect(() => {
        document.title = 'Home | INV';
    });

    return (
        <>
            <Header otClass='transparent' />
            <section className="banner home_banner">
                <Container >
                    <Row className='align-items-center'>
                        <Col lg={7} md={7} sm={12}>
                            <div className="caption">
                                <h2 className='txt_white'>Invention and Innovation</h2>
                                <p className='txt_white'>We build custom software that drives innovation</p>
                                {/* <div className="wrapper">
                                    <CommonBtn
                                        myClass='txt_white common_btn blue hover'
                                        myEvent={() => navigate('/')}
                                    >
                                        Schedule Service
                                    </CommonBtn>
                                    <span className='txt_white'>Or Call (+92) 3334228324</span>
                                </div> */}
                            </div>
                        </Col>
                        <Col lg={5} md={5} sm={12}>
                            <div className="img_wrapper">
                                <img src={home_banner} alt="" className="img_fluid" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <main className='home_page'>
                <section className='intro_sec'>
                    <Container>
                        <Row>
                            <Col>
                                <h2 className='sec_hd txt_blue text-center'>
                                    Why Choose Us?
                                </h2>

                                <div className="card">
                                    <div className="wrapper">
                                        <div className="img_wrapper">
                                            <img src={Questions_bro} alt="" className="img_fluid" />
                                        </div>
                                        <div className="disc">
                                            <p className='para'>
                                                While providing a range of IT solutions and digital technology services to startups and large enterprises, alongside retailers and resellers, we empower our customers not only to grow, but to achieve excellence in everything they do.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className="bg_img">
                        <img src={yellow_circle_sm} alt="" className="img_fluid" />
                    </div>
                </section>

                <section className='service_cards'>
                    <Container>
                        <Row>
                            <Col>
                                <h2 className='sec_hd txt_blue text-center'>
                                    Our Services
                                </h2>
                                <div className="flex_wrapper">
                                    {
                                        sericeCards.map((item: IsericeCards) => {
                                            return (
                                                <div className="box" key={Math.random()}>
                                                    <div className="icon_wrapper">
                                                        <img src={item.imga} alt="" className="img_fluid imga" />
                                                        <img src={item.imgb} alt="" className="img_fluid imgb" />
                                                    </div>
                                                    <h4>
                                                        {item.txt}
                                                    </h4>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className="bg_img">
                        <img src={yellow_circle_bg} alt="" className="img_fluid" />
                    </div>
                </section>

                <section className='yearly_success'>
                    <Container>
                        <Row>
                            <Col>
                                <h2 className='sec_hd txt_blue text-center w_sm'>
                                    We Completed {reviews.length}+ Projects Successfully & Counting
                                </h2>
                                <p className="sec_para w_sm text-center">
                                    A technologically connected world where no entity is left behind. We at IINV empower businesses with innovative IT enabled services globally.
                                </p>
                                <div className="flex_wrapper">
                                    {
                                        projectCards.map((item: IprojectCards) => {
                                            return (
                                                <div className="box" key={Math.random()}>
                                                    <div className="icon_wrapper">
                                                        <img src={item.img} alt="" className="img_fluid" />
                                                    </div>
                                                    <div className="caption">
                                                        <h4>
                                                            {item.hd}
                                                        </h4>
                                                        <p className='para'>
                                                            {item.txt}
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className="bg_img">
                        <img src={blue_flow} alt="" className="img_fluid" />
                    </div>
                </section>

                <section className='testi_sec'>
                    <Container className='fluid_sm' >
                        <Row>
                            <Col>
                                <h2 className='sec_hd txt_blue text-center w_sm'>
                                    Testimonials
                                </h2>
                                <SlickSlider
                                    items={reviews}
                                />
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <Footer />
            <GotoTop />
        </>
    );
}

export default Home;
import React, { useEffect } from 'react';
import { Col, Container, Row, Accordion } from 'react-bootstrap';
import { Header, Footer, SlickSlider } from '../../component';
import { logo, login_poster } from '../../assets'
import { Link } from 'react-router-dom';

const Login: React.FunctionComponent = () => {
    useEffect(() => {
        document.title = 'Login | INV';
    });
    return (
        <div className="main_wrapper">
            <Header otClass="transparent" />
            <main>
                <section className="login_sec">
                    <Container fluid>
                        <div className="d-flex md_block">
                            <div className="form_wrapper text-center mb-3">
                                <div className="form_logo">
                                    <img src={logo} className="img-fluid"/>
                                </div>
                                <h4 className="sec_hd txt_dark_blue mt-3">
                                    LOGIN HERE
                                </h4>
                                <form className="form_container">
                                    <input type="text" className="box_shd2" placeholder="ID" />
                                    <input type="password" className="box_shd2" placeholder="Password" />
                                    <Link to="#" className="d-block form_txt txt_dark_blue mt-5">Forgot Password?</Link>
                                    <Link to="#" className="d-block form_txt txt_dark_blue mb-5">Forgot ID?</Link>
                                    <button className="btn form_btn common_btn txt_white hover">LOGIN</button>
                                    <p className="mt-5 mb-5 form_para">Don't Have an account? <Link to="#" className="form_txt txt_dark_blue"> Create an Account Now</Link></p>
                                </form>
                            </div>
                            <div className="login_bg_poster">
                                <img src={login_poster} />
                            </div>
                        </div>
                    </Container>
                </section>
            </main>
            <Footer />
        </div>
    )
}

export default Login;

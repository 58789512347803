import React, { useEffect, useState } from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { logo } from '../../assets';
import { useNavigate } from 'react-router-dom';

const navLinks = [
    {
        txt: 'Home',
        url: '/',
    },
    {
        txt: 'Blog',
        url: '/coming-soon',
    },
    {
        txt: 'Portfolio',
        url: '/portfolio',
    },
    {
        txt: 'Services',
        url: '/coming-soon',
    },
    {
        txt: 'About Us',
        url: '/coming-soon',
    },
];

interface InavLinks {
    txt: string,
    url: string,
}

const Header: React.FunctionComponent<{otClass?: string}> = ({ otClass }) => {
    // const { otClass } = props;
    return (
        <header className={otClass || ""}>
            <Navbar expand="lg">
                <Container className='fluid_sm'>
                    <Link to='/' className='logo_container'>
                        <img src={logo} alt="website logo" className='img_fluid' />
                    </Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav">
                        <span></span>
                        <span></span>
                        <span></span>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className='ms-auto'>
                            {navLinks.map((item: InavLinks) => {
                                return (
                                    <div key={Math.random()}
                                        className='nav_item'
                                    >
                                        <NavLink
                                            to={item.url}
                                            className={otClass ? 'nav_link txt_white' : 'nav_link text-dark'}
                                        >
                                            {item.txt}
                                        </NavLink>
                                    </div>
                                );
                            })
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header >
    );
}

export default Header;
import { eclipse1 } from "../../assets";

export const reviews = [
  {
    img: eclipse1,
    projectTitle: "Web app UX help",
    review: "Invention and Innovation work is exceptional! Their creativity shines through every element they design, creating a seamless and visually captivating user experience. Their ability to blend aesthetics with functionality is commendable. I highly recommend their services for anyone seeking a top-notch UI design!",
    clientName: "Ricardo C.",
  },
  {
    img: eclipse1,
    projectTitle: "Cspathway College Map App",
    review: "Hamid and his team were great. Professional, communicative and extremely helpful during the process. Looking forward to working with them in the future.",
    clientName: "Corry Hanks.",
  },
  {
    img: eclipse1,
    projectTitle: "Mobile App Development",
    review: "Second project I worked on with him, very understanding, good quality work and overall a pleasure working with them.",
    clientName: "Morris Hoveling.",
  },
  {
    img: eclipse1,
    projectTitle: "WoundCharts ios and android update",
    review: "Good communication and patience",
    clientName: "Fernando Torres.",
  },
  {
    img: eclipse1,
    projectTitle: "Continuation of Travel App Project",
    review: "Once again they have delivered with outstanding quality. It’s always a pleasure to work together with you! Hamid and his team are very responsive and do everything to satisfy their clients. Thank again for your work!",
    clientName: "Daniel Gruber.",
  },
  {
    img: eclipse1,
    projectTitle: "React Component-Stock Project",
    review: "It was Saturday night when we fixed the last parts on the express project. That is part to understand that Invention and Innovation has a focus on the client. We will keep working now on a new project.",
    clientName: "Timo B.",
  },
  {
    img: eclipse1,
    projectTitle: "Development of Travel App",
    review: "I can’t thank Hamid and the IINV Tech team enough! Great support throughout the entire project with the outstanding quality of their work! We’re working together on a longer term for a larger project and I will definitely rehire and continue working with them. Once again, I highly appreciate everything done so far.",
    clientName: "Daniel Gruber.",
  },
  {
    img: eclipse1,
    projectTitle: "Build me an iOS and Android app",
    review: "It was great working with him, very professional and always ready to respond.",
    clientName: "Morris Hoveling",
  },
  {
    img: eclipse1,
    projectTitle: "Build mobile app UI for Android",
    review: "Very good work, understand my project at once always on time very professional 100% recommended!!",
    clientName: "Amir K.",
  },
  {
    img: eclipse1,
    projectTitle: "Privately Hired Project",
    review: "Awesome work. On time & budget-friendly.",
    clientName: "Amir K.",
  },
  {
    img: eclipse1,
    projectTitle: "Posiedon Fuel WebApp",
    review: "Good company to work with on an app, quite a bit delayed in getting some things completed but it worked out in the end.",
    clientName: "Ted N.",
  },
  {
    img: eclipse1,
    projectTitle: "Moneyvizer pro",
    review: "Great job, the second time they have made a design job. Will work again with them. Thanks.",
    clientName: "Samuel H.",
  },
  {
    img: eclipse1,
    projectTitle: "Desarrollador Frontend (React)",
    review: "He fulfilled what was requested, very good",
    clientName: "Joel M.",
  },
  {
    img: eclipse1,
    projectTitle: "Privately Hired Project",
    review: "Second project with this developer. He is trustworthy and willing to go the extra mile. Excellent product with professional administration and communication. Already looking to do another project with this quality developer. Highly recommended.",
    clientName: "Santokh S.",
  },
  {
    img: eclipse1,
    projectTitle: "Recreate an App design for Website",
    review: "Very good experience. They work clearly and to the point, communication was good and the work met my expectations. I would work with them again for sure.",
    clientName: "Samuel H.",
  },
  {
    img: eclipse1,
    projectTitle: "Revisions to previous work",
    review: "Recommend for work. Good communication!",
    clientName: "Marcell V.",
  },
  {
    img: eclipse1,
    projectTitle: "Finance Web App",
    review: "Second time working with I&I excellent on time good communication.",
    clientName: "Marcell V.",
  },
  {
    img: eclipse1,
    projectTitle: "Design and develop a similar landing page",
    review: "Awesome freelancer. He done A++++ quality work. Thanks.",
    clientName: "Md Helal U.",
  },
  {
    img: eclipse1,
    projectTitle: "Convert XD file to web page",
    review: "Very good result and with full quality. His main priorities are quality, not payment. Delivery on time and he did many changes with free of charge even was not in requirements. Very happy to work with him. I will send extra work in upcoming days.",
    clientName: "Ahmed H.",
  },
  {
    img: eclipse1,
    projectTitle: "Privately Hired Project",
    review: "It was great work. Thank you very much.",
    clientName: "Ahmed H.",
  },
  {
    img: eclipse1,
    projectTitle: "build basic website",
    review: "Good communication, delivered on time and I am happy with the work. Recommended.",
    clientName: "Steve W.",
  },
  {
    img: eclipse1,
    projectTitle: "Application Submission Form",
    review: "He is very capable and willing to make changes to get the job done.",
    clientName: "Mywebsite E.",
  },
  {
    img: eclipse1,
    projectTitle: "Clover Flex Reporting App",
    review: "Great to work with - on time and on budget. Excellent communication. Would definitely use them again.",
    clientName: "Santokh S.",
  },
  {
    img: eclipse1,
    projectTitle: "Frontend Introduction and User Page for Digital Fund",
    review: "Excellent work, very good communication. Kept working with them later!",
    clientName: "Marcell V.",
  },
  {
    img: eclipse1,
    clientName: "Santokh S",
    review: "Second project with this developer. He is trustworthy and willing to go the extra mile. Excellent product with professional administration and communication. Already looking to do another project with this quality developer. Highly recommended.",
  },
  {
    img: eclipse1,
    clientName: "Samuel H",
    review: "Very good experience. They work clearly and to the point, communication was good and the work met my expectations. I would work with them again for sure.",
  },
  {
    img: eclipse1,
    clientName: "Marcell V",
    review: "Second time working with I&I excellent on time good communication.",
  },
  {
    img: eclipse1,
    clientName: "Md Helal U",
    review: "awesome freelancer. he done A++++ quality work. thanks.",
  },
  {
    img: eclipse1,
    clientName: "Marcell V",
    review: "Recommend for work. Good communication.",
  },
  {
    img: eclipse1,
    clientName: "Santokh S",
    review: "Second project with this developer. He is trustworthy and willing to go the extra mile. Excellent product with professional administration and communication. Already looking to do another project with this quality developer. Highly recommended.",
  },
];

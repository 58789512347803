import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
    arrow_right,
} from '../../assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';

const col1 = [
    { txt: 'Locations', url: '/coming-soon' },
    { txt: 'Franchise With Us', url: '/coming-soon' },
    { txt: 'Partners', url: '/coming-soon' },
    { txt: 'About Us', url: '/coming-soon' },
    { txt: 'Make Fetch Happens!', url: '/coming-soon' },
]

const col2 = [
    { txt: 'Mobile Application', url: '/coming-soon' },
    { txt: 'React', url: '/coming-soon' },
    { txt: 'React Native', url: '/coming-soon' },
    { txt: 'Wordpress', url: '/coming-soon' },
    { txt: 'Graphics Designing', url: '/coming-soon' },
]

const col3 = [
    { txt: 'Home', url: '/' },
    { txt: 'Services', url: '/coming-soon' },
    { txt: 'Portfolio', url: '/portfolio' },
    { txt: 'Blog', url: '/coming-soon' },
    { txt: 'Contact Us', url: '/contact-us' },
]

const socials = [
    { icon: faFacebookF, url: 'https://www.facebook.com/iinvtech' },
    { icon: faLinkedinIn, url: 'https://www.linkedin.com/company/invention-and-innovation' },
    { icon: faInstagram, url: 'https://www.instagram.com/iinv_tech/' },
]

interface Isocials {
    icon: any,
    url: string,
}
interface icol {
    txt: string,
    url: string,
}

const Footer: React.FunctionComponent = () => {

    return (
        <footer>
            <Container className='fluid_sm'>
                <Row>
                    <Col lg={3} md={3} sm={6} className='mb_sm'>
                        <FooContainer
                            title='About'
                            links={col1}
                        />
                    </Col>
                    <Col lg={3} md={3} sm={6} className='mb_sm'>
                        <FooContainer
                            title='Technologies'
                            links={col2}
                        />
                    </Col>
                    <Col lg={3} md={3} sm={6}>
                        <FooContainer
                            title='Quick Links'
                            links={col3}
                        />
                    </Col>
                    <Col lg={3} md={3} sm={6}>
                        <FooContainer
                            title='Contact Us'
                        >
                            <p>Sign up to receive the Fetch!
                                <br />
                                Family Newsletter
                            </p>
                            <form action="">
                                <div className="input_wrapper">
                                    <input
                                        type="text"
                                        name=""
                                        id=""
                                        placeholder='Email Address'
                                    />
                                    <button className="icon_wrapper">
                                        <img src={arrow_right} alt="" className="img_fluid" />
                                    </button>
                                </div>
                            </form>
                            <ul className='socials'>
                                {
                                    socials.map((item: Isocials) => {
                                        return (
                                            <li key={Math.random()}>
                                                <a
                                                    href={item.url}
                                                    target='_blank'
                                                    rel="noreferrer"
                                                >
                                                    <div className="icon">
                                                        <FontAwesomeIcon icon={item.icon} />
                                                    </div>
                                                </a>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </FooContainer>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;

const FooContainer: React.FunctionComponent<any> = (props) => {

    const { title, links } = props;
    return (
        <div className="foo_wrapper">
            <h4 className="foo_title">
                {title}
            </h4>
            {props.children ?
                props.children
                :
                <ul className='foo_ul'>
                    {links.map((item: icol) => {
                        return (
                            <li key={Math.random()}>
                                <Link
                                    to={item.url}
                                >
                                    {item.txt}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            }
        </div>
    );
}